const ModalRemboursement = ({ id, table, montant }) => {
    return (
        <div  className="modal fade" id="modalRemboursement" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div  className="modal-dialog">
                <div  className="modal-content">
                    <div  className="modal-header">
                        <h1  className="modal-title fs-5" id="exampleModalLabel">Saisie Remboursement</h1>
                        <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form >

                        <div  className="modal-body">
                           <div className="form-group">
                            <label htmlFor="" className="fs-5">Montant</label>
                            <input type="number" className="form-control"/>
                           </div>
                        </div>
                        <div  className="modal-footer">
                            <button type="button"  className="btn btn-danger" data-bs-dismiss="modal">Annuler</button>
                            <button type="button"  className="btn btn-success">Payer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ModalRemboursement;