import React from 'react';
import ReactDOM from 'react-dom/client';

import './css/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'



// RESSOURCES EXTERNE DU TEMPLATE
// -----css--------------
import './assets/plugins/simplebar/css/simplebar.css'
import './assets/css/pace.min.css'
import './assets/css/bootstrap.min.css'
import './assets/css/app.css'
import './assets/css/icons.css'
import './assets/css/dark-theme.css'
import './assets/css/semi-dark.css'
import './assets/css/header-colors.css'
import './assets/plugins/vectormap/jquery-jvectormap-2.0.2.css'
import './css/style.css'

import { ConstextProvider } from './components/ContextProvider.js';
import { RouterProvider } from 'react-router-dom';
import router from './router.js';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConstextProvider>
    <RouterProvider router={router} />
    </ConstextProvider>
  </React.StrictMode>
);

