import { Navigate, Outlet } from "react-router-dom";
import { token } from "../components/const";
const GuestLayout = () => {
  
    if (token) {
        return <Navigate to="/" />
    }
    return (<>

        <Outlet />

    </>);
}

export default GuestLayout;