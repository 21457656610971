
import UseFetch from "../components/UseFetch";
import SearchField from "../components/SeachField";
import { Loading } from "../components/const";
import { useState } from "react";
import ModalRemboursement from "./ModalRemboursement";
const Solde = () => {
    const { data: fournisseurs, isloading } = UseFetch('fournisseurs/solde');
    const [id, setId] = useState(0);
    const { data: factures, isloading: load } = UseFetch('fournisseurs/factures')
    const [factureById, setById] = useState(null);
    const handeById = (id) => {
        setById(factures.filter(fact => (+fact.id) === (+id)))
    }

    return (
        <div className="Solde">
            <div className="row my-2">
                <div className="col-lg-6 h2 text-primary">
                    SOLDE DES FOURNISSEURS
                </div>
                <div className="col-lg-6 text-end">
                    <SearchField />
                </div>
            </div>
            {
                isloading ? <Loading /> : fournisseurs ?
                    <div className="table-responsive p-2">
                        <table className="table table-hover table-striped ">
                            <thead className="table-primary">
                                <tr>
                                    <td>N°</td>
                                    <td>PRENOMS</td>
                                    <td>NOM</td>
                                    <td>TELEPHONE</td>
                                    <td>ADRESSE</td>
                                    <td>SOLDE</td>
                                    <td className="text-end">ACTIONS</td>
                                </tr>

                            </thead>
                            <tbody>
                                {
                                    fournisseurs.map((fourn, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{fourn.prenoms}</td>
                                            <td>{fourn.nom}</td>
                                            <td>{fourn.telephone}</td>
                                            <td>{fourn.adresse}</td>
                                            <td>{fourn.solde}</td>
                                            <td className="text-end">
                                                <button disabled data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-success" onClick={() => handeById(fourn.id)}>FACTURE NON REMBOURSEES</button>
                                            </td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div> :
                    <div className="row justify-content-center p-2 bg-danger-subtle text-danger fs-5">Aucun fournisseur trouvé!</div>
            }




            {/* Modal */}
            <div className="AddModal">
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
                    <div className="modal-dialog modal-xl ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Liste des produit du conteneur</h1>
                                <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">

                                <div className="row p-2 table-responsive">
                                    {load ? <Loading /> : <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>N°</th>
                                                <th>NUM-FACTURE</th>
                                                <th>MONTANT FACT</th>
                                                <th>MONTANT PAYE</th>
                                                <th>RESTE A PAYER</th>
                                                <th>ACTIONS</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                factureById && factureById.map((fact, index) => (
                                                    <tr key={index + 1}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>{fact.numero}</td>
                                                        <td>{fact.montant}</td>
                                                        <td>{`${fact.paye.toLocaleString()}`}</td>
                                                        <td>{`${fact.solde.toLocaleString()}`}</td>
                                                        <td>
                                                            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalRemboursement">REMBOURSER</button>
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>

                                    </table>
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Fin modal */}
            {/* modal Remboursement */}
            <ModalRemboursement />
        </div>

    );
}

export default Solde;