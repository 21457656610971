import { Loading } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";

const Fournisseurs = () => {
    const { data: fournisseurs, isloading } = UseFetch('fournisseurs/liste');
    return (
        <div className="Fournsisseur">
            <div className="row my-2">
                <div className="col-lg-6 h2 text-primary">
                    LISTE DES FOURNISSEURS
                </div>
                <div className="col-lg-6 text-end">
                    <SearchField />
                </div>
            </div>
            {
                isloading ? <Loading /> : fournisseurs ?
                 <div className="table-responsive p-2">
                    <table className="table table-hover table-striped ">
                        <thead className="table-primary">
                            <tr>
                                <td>N°</td>
                                <td>PRENOMS</td>
                                <td>NOM</td>
                                <td>TELEPHONE</td>
                                <td>ADRESSE</td>
                                <td>DATE AJOUT</td>
                                <td className="text-end">ACTIONS</td>
                            </tr>

                        </thead>
                        <tbody>
                        {
                            fournisseurs.map((fourn,index)=>(
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{fourn.prenoms}</td>
                                    <td>{fourn.nom}</td>
                                    <td>{fourn.telephone}</td>
                                    <td>{fourn.adresse}</td>
                                    <td>{new Date(fourn.created_at).toLocaleDateString()}</td>
                                    <td className="text-end">
                                        <button className="btn btn-primary">Editer</button>
                                    </td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                 </div> : 
                <div className="row justify-content-center p-2 bg-danger-subtle text-danger fs-5">Aucun fournisseur trouvé!</div>
            }

        </div>
    );
}

export default Fournisseurs;