
import SearchField from "../components/SeachField";
import { Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
const ListeProduitParMagasin = () => {
    const { data: produits, isloading } = UseFetch('mag/stocks');
    return (
        <div className="produit">
            <div className="row">
                <div className="col-lg-6 h3 text-primary"> SITUATION DU STOCK DU MAGASIN</div>
            </div>
            <div className="accordion accordion-flush" id="stock">
                <div className="accordion-item my-3 shadow">
                    <h2 className="accordion-header ">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#stock-actuel" aria-expanded="false" aria-controls="stock-actuel">
                            <span className="fs-4"> Stock Actuel</span>
                        </button>
                    </h2>
                    <div id="stock-actuel" className="accordion-collapse collapse" data-bs-parent="#stock">
                        <div className="accordion-body bg-transparent">
                            <div className="row justify-content-end">
                                <div className="col-lg-6">
                                    <SearchField />
                                </div>
                            </div>
                            <div className="row p-2">
                                {
                                    isloading ? <Loading /> : produits?.length ? <div className="table-responsive">

                                        <table className="table table-white table-bordered table-hover table-striped">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th>N°</th>
                                                    <th>PRODUITS</th>
                                                    <th>QUANTITE</th>
                                                    <th>PVU</th>
                                                </tr>

                                            </thead>

                                            <tbody>
                                                {
                                                    produits.map((prod, index) => (
                                                        (prod.statut === 'Validé') && <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{prod.produit}</td>
                                                            <td>{prod.quantite}</td>
                                                            <td>{prod.pvu}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>

                                        </table>

                                    </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger">Aucun produit trouve</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item my-3 shadow">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <span className="fs-4"> Stock en attente</span>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#stock">
                        <div className="accordion-body">
                            <div className="row justify-content-end">
                                <div className="col-lg-6">
                                    <SearchField />
                                </div>
                            </div>

                            <div className="row p-2">
                                {
                                    isloading ? <Loading /> : produits?.length ? <div className="table-responsive">

                                        <table className="table table-white table-bordered table-hover table-striped">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th>N°</th>
                                                    <th>PRODUITS</th>
                                                    <th>QUANTITE</th>
                                                    <th>PVU</th>
                                                </tr>

                                            </thead>

                                            <tbody>
                                                {
                                                    produits.map((prod, index) => (
                                                        (prod.statut === 'En attente') && <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{prod.produit}</td>
                                                            <td>{prod.quantite}</td>
                                                            <td>{prod.pvu}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>

                                        </table>

                                    </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger">Aucun produit trouve</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListeProduitParMagasin;