import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import TimeLine from "../components/TimeLine";
import Swal from "sweetalert2";
const SelectedProducts = () => {
    let selectedProds = JSON.parse(localStorage.getItem('selectedProducts'));
    let [productSelected, setSelectedProd] = useState(selectedProds ? selectedProds : []);
    let [isvalide, setIsValide] = useState(true);
    const navigate = useNavigate();
    const handleQte = (e, prod) => {
        prod.qte = e.target.value
        localStorage.setItem('selectedProducts', JSON.stringify(productSelected))
    }
    const handlePrix = (e, prod) => {
        prod.prix = e.target.value
        localStorage.setItem('selectedProducts', JSON.stringify(productSelected))
    }


    const handleUnSelectProduct = (e, prod) => {
        e.preventDefault()
        if (productSelected.find(p => p.id === prod.id)) {
            setSelectedProd(productSelected.filter(elm => elm.id !== prod.id))
        }
    }
    const handleSuivant = () => {
        let isvalide = -1;
        selectedProds.forEach(p => {
            if (+p.qte < 1 || +p.prix < 1000) {
                isvalide = +selectedProds.indexOf(p) + 1;
            }
        });
        if (isvalide>=0) {
            Swal.fire({
                title: "Erreur de saisie!",
                text: "Vériez le prix et la quantié sur la ligne " + isvalide,
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        navigate('/conteneurs/add-enregistrer');
    }
    useEffect(() => {
        localStorage.setItem('selectedProducts', JSON.stringify(productSelected))
    }, [productSelected])

    return (
        <div className="SelectdProduct">
            <table>
                <tbody><TimeLine colorFournisseur="success" colorProd="success" colorPrix="primary" coloFin="secondary-subtle" />
                </tbody>
            </table>
            <div className="table-responsive-p-2 mt-2">
                <table className="table table-hover table-striped table-secondary  table-bordered">
                    <thead className="table-dark">
                        <tr>
                            <td hidden>ID</td>
                            <td >N°</td>
                            <td>Designation Produit</td>
                            <td className="text-end" width="150">Qte</td>
                            <td width="300">PAU</td>
                            <td className="text-end">Action</td>
                        </tr>
                    </thead>
                    <tbody id="selectedProducts">
                        {
                            productSelected.length ? productSelected.map((pro, i) => (
                                <tr key={pro.id}>
                                    <td hidden className="id"> {pro.id}</td>
                                    <td > {i + 1}</td>
                                    <td className="prod"> {pro.prod}</td>
                                    <td className=" text-end qte">
                                        <input type="number" className="form-control" defaultValue={+pro.qte} onChange={(e) => handleQte(e, pro)} />

                                    </td>
                                    <td >
                                        <input type="number" className="form-control prix" defaultValue={pro.prix} onChange={(e) => handlePrix(e, pro)} />
                                    </td>
                                    <td className="text-end">

                                        <button onClick={(e) => handleUnSelectProduct(e, pro)} className="btn btn-danger m-1" >Retirer</button>
                                    </td>
                                </tr>
                            )) : <tr className="bg-danger"><td colSpan={5} className="p-2 bg-danger text-center text-danger">Vous n'avez pas choisi un produit!</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="row mt-2 p-2 justify-content-center">
                <div className="col-lg-4 text-center">
                    <NavLink to="/conteneurs/add-products" className="btn btn-primary m-1">Precedant</NavLink>
                    <button className="btn btn-primary m-1" onClick={() => handleSuivant()}>Suivant</button>
                </div>
            </div>
        </div>
    );
}

export default SelectedProducts;