import { useEffect, useState } from "react";
import { Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
import SearchField from "../components/SeachField";

const Facturation = () => {
    const { data: produits, isloading } = UseFetch('mag/stocks')
    const [selected, setSelect] = useState([]);
    const [gonext, setGonext] = useState(false);
    const handSelect = (prod) => {
        if (!(selected.find(p => (p.id === prod.idproduit && p.pvu === prod.pvu)))) {
            setSelect([
                ...selected,
                {
                    'id': prod.idproduit,
                    'produit': prod.produit,
                    'quantite': 1,
                    'pvu': prod.pvu,
                    'idstock': prod.id,
                    'qte_total': +prod.quantite,
                }
            ]);
        }

    }
    return (
        <div className="Facturation container-fluid">
            {!gonext && (
                isloading ? <Loading /> :
                    produits ? <div className="row">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-4 fs-4 fw-bold fst-italic">
                                {selected.length > 0 && `${selected.length} produit(s) selectionné(s)`}
                            </div>
                            <div className="col-lg-4">
                                <SearchField />
                            </div>
                        </div>
                        <div className="row p-2">
                            {
                                produits.map((prod, index) => (
                                    <div key={index} className="col-sm-3  shadow p-1 radius-15 m-0 ">
                                        <div className="card h-100 p-2 border-0 d-flex flex-column justify-content-center align-items-center m-0">
                                            <span className="text-center prod-name">{prod.produit}</span>
                                            <small className="fw-bold text-secondary">Qte: {prod.quantite}</small>
                                            <strong className="prix text-center">{`${prod.pvu.toLocaleString()} GNF`}</strong>
                                            <button className="mt-auto btn btn-outline-success  justify-self-end" onClick={() => handSelect(prod)}>Ajouter</button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger fs-3">
                        Votre stock est actuellement vide !
                    </div>


            )



            }

            {gonext && <div className="row">
                <div className="row px-4" >
                    <div className="form-group ">
                        <label htmlFor="client" >Client</label>
                        <input type="text" className="form-control" id="client" name="client" />
                    </div>
                    <div className="form-group ">
                        <label htmlFor="telephone" >Téléphone</label>
                        <input type="number" className="form-control" id="telephone" name="telephone" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="adresse" >Adresse</label>
                        <input type="number" className="form-control" id="adresse" name="adresse" />
                    </div>
                </div>
                <div className="table-responsive p-2">
                    <table className="table table-white table-hover table-striped">
                        <thead className="table-primary">
                            <tr>
                                <th>N°</th>
                                <th>PRODUITS</th>
                                <th>QTE</th>
                                <th>PVU</th>
                                <th>MONTANT</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selected?.map((prod, ind) => (
                                    <tr key={ind}>
                                        <td>{ind + 1}</td>
                                        <td>{prod.produit}</td>
                                        <td>{prod.quantite}</td>
                                        <td>{prod.pvu}</td>
                                        <td>{prod.pvu * prod.quantite}</td>
                                        <td className="text-end ">
                                            <i className="bx bx-plus mx-auto a p-1 text-success fs-5"></i>
                                            <i className="bx bx-minus mx-auto a p-1  text-warning fs-5"></i>
                                            <i className="bx bx-x mx-auto a p-1  text-danger fs-5"></i>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            }

            {(selected.length > 0) && <div className="row justify-content-end p-2 mt-5">
                <div className=" modal-footer">
                    {!gonext &&
                        <button className="btn btn-primary m-1" onClick={() => setGonext(true)}>Suivant
                            <i className="bx bx-last-page" ></i>
                        </button>
                    }
                    {gonext && <button className="btn btn-danger m-1" onClick={() => setGonext(false)}> <i className="bx bx-first-page"></i> Retour
                       
                    </button>}
                    {(selected.length > 0 && gonext) && <button className="btn btn-success m-1">Enregistrer</button>}
                </div>

            </div>}
        </div>
    );
}

export default Facturation;