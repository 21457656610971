import { useSearchParams } from "react-router-dom";
import UseFetch from "../components/UseFetch";
import { Loading} from "../components/const";

const DashBoardMag = () => {
    const [params, setParams] = useSearchParams();
    const id = params.get('id');
    const { data: magasin, isloading } = UseFetch(`magasins/${id}`)

    return (
        <div className="darshboard">
            {
                isloading ? <Loading /> : <div className="container-fluid p-2 bg-danger">
                  
                </div>
            }
        </div>
    );
}

export default DashBoardMag;