const DbAdmin = () => {
    return (
        <>
            <div className="row mb-2 bg-white p-3 rounded justify-content-between">
                <div className="col h3 text-primary">Tableau de bord</div>
                <form action="" className="col-lg-6 ">
                    <div className="row align-items-center">
                        <div className="form-group  col-5">

                            <input type="date" className="form-control" id="date1" name="date1" />
                        </div>
                        <div className="form-group col-5">

                            <input type="date" className="form-control" id="date2" name="date2" />

                        </div>
                        <button type="submit" className="btn btn-primary col-2 align-self-end"><i className="bx bx-search"></i></button>
                    </div>
                </form>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                <div className="col">
                    <div className="card radius-10 border-start border-0 border-3 border-info">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-secondary">Total Produits</p>
                                    <h2 className="my-1  text-info">4805</h2>

                                </div>
                                <div className="widgets-icons-2 p-3 rounded-circle bg-gradient-scooter text-white ms-auto"><i className='bx bxs-cart fs-1'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card radius-10 border-start border-0 border-3 border-primary">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-secondary">Total Magasins</p>
                                    <h2 className="my -1 text-primary">10</h2>

                                </div>
                                <div className="widgets-icons-2 rounded-circle bg-primary text-white ms-auto p-3"><i className='bx bx-building-house fs-1'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card radius-10 border-start border-0 border-3 border-info">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-secondary">Total Contenaires</p>
                                    <h2 className="my-1  text-info">+40</h2>

                                </div>
                                <div className="widgets-icons-2 rounded-circle bg-info text-white ms-auto p-3"><i className='bx bx-card fs-1' ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card radius-10 border-start border-0 border-3 border-warning">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-secondary">Total Utilisateurs</p>
                                    <h2 className="my-1  text-warning">+20</h2>

                                </div>
                                <div className="widgets-icons-2 rounded-circle bg-gradient-blooker text-white p-3 ms-auto"><i className='bx bxs-group fs-1'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row my-2 ">
                <div className="col-12 col-lg-8 p-2">
                    <div className="card radius-10 h-100  ">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <h6 className="mb-0">Rendement par magasin</h6>
                                </div>

                            </div>

                            <div className="chart-container-1">
                                <canvas id="chart1"></canvas>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-12 col-lg-4 p-2">
                    <div className="card radius-10 h-100 ">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <h6 className="mb-0">Top 3 des Magasins </h6>
                                </div>
                            </div>
                            <div className="chart-container-2 mt-4">
                                <canvas id="chart2"></canvas>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default DbAdmin;