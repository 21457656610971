const TimeLine = ({ colorFournisseur, colorProd, colorPrix, coloFin }) => {
    return (
        <>
            <tr>

                <td className="time text-center  me-5 w-25">
                    <span className={`time-circle bg-${colorFournisseur} `}>

                    </span>
                </td>
                <td className="time text-center text-secondary  w-25">
                    <span className={`time-circle bg-${colorProd} `}>

                    </span>
                </td>
                <td className="time text-center text-secondary ms-5 w-25">
                    <span className={`time-circle bg-${colorPrix} `}>

                    </span>
                </td>
                <td className="time text-center text-secondary ms-5 w-25">
                    <span className={`time-circle bg-${coloFin} `}>

                    </span>
                </td>
                <td className="time text-center text-secondary ms-5 w-25">
                    {/* <div className="time-circle bg-secondary-subtle">

                    </div> */}
                </td>
            </tr>
            <tr>

                <td className={`time text-center fs-5 text-${colorFournisseur}`}>
                    1- Infos Fournisseur
                </td>
                <td className={`time text-center fs-5 text-${colorProd}`}>
                    2- Produits
                </td>
                <td className={`time text-center fs-5 text-${colorPrix}`}>
                    3- Prix produits
                </td>
                <td className={`time text-center fs-5 text-${coloFin}`}>
                    4- Terminer
                </td>
            </tr>


        </>
    );
}

export default TimeLine;