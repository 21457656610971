import TimeLine from "../components/TimeLine";
import { useEffect, useState } from "react"
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { defaulUrl, getFournisseur, Loading, saveFournisseur, decryptData } from "../components/const";
import UseFetch from "../components/UseFetch";

const AddFournisseur = () => {

    let fournisseur = JSON.parse(localStorage.getItem('Fournisseur'));
    const navigate = useNavigate();
    let [firname, setFirstName] = useState(fournisseur ? fournisseur.prenoms : '')
    let [lastname, setLastName] = useState(fournisseur ? fournisseur.nom : '')
    let [telephone, setTel] = useState(fournisseur ? fournisseur.telephone : '')
    let [adresse, setAdresse] = useState(fournisseur ? fournisseur.adresse : '')
    let [isdataValide, setIsDatavalide] = useState(false);
    const [isloading, setIsloadind] = useState(false)

    const handleIsdataValide = () => {

        if (firname && lastname && telephone && adresse) {
            setIsDatavalide(true);
            saveFournisseur(lastname, firname, telephone, adresse);
            navigate('/conteneurs/add-products');
        } else {
            setIsDatavalide(false)
        }
    }

    const handeGetfourByTel = (tel) => {
        setIsloadind(true)
        fetch(`${defaulUrl}fournisseurs/byphone/${tel}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {

            return response.json();
        }).then(data => {
            setIsloadind(false)
            if (data.length) {
                setFirstName(data[0].prenoms);
                setLastName(data[0].nom);
                setAdresse(data[0].adresse);

            }

        })
    }
    return (
        <div className="AddFournisseur">
            <div className="FournisseurInfo">
                <table>
                    <tbody>
                        <TimeLine colorFournisseur="primary" colorProd="secondary-subtle" colorPrix="secondary-subtle" coloFin="secondary-subtle" />
                    </tbody>
                </table>
                <form >
                    <div className="row p-3 ">

                        {isloading && <Loading />}
                        <div className="col-lg-6 form-group my-2 p-3">
                            <label htmlFor="telephone" className="fs-5">Tel Fournisseur <span className="text-danger">(*)</span> </label>
                            <input type="number" className="form-control h-100" defaultValue={telephone} onChange={(e) => setTel(e.target.value)} required name="phone" onBlur={(e) => handeGetfourByTel(e.target.value)} />
                            {(isdataValide === true && !telephone == 0) && <span className="text-danger">Entrez le telephone du fournisseur</span>}
                        </div>
                        <div className="col-lg-6 form-group my-2 p-3">
                            <label htmlFor="nom" className="fs-5">Nom Fournisseur <span className="text-danger">(*)</span> </label>
                            <input type="text" className="form-control h-100" defaultValue={lastname} onChange={(e) => setLastName(e.target.value)} required />
                            {<span className="text-danger"></span>}
                        </div>
                        <div className="col-lg-6 form-group my-2 p-3">
                            <label htmlFor="prenoms" className="fs-5">Prenom(s) Fournisseur <span className="text-danger">(*)</span> </label>
                            <input type="text" className="form-control h-100" defaultValue={firname} onChange={(e) => setFirstName(e.target.value)} required />
                            {<span className="text-danger"></span>}
                        </div>
                        <div className="col-lg-6 form-group my-2 p-3">
                            <label htmlFor="adresse" className="fs-5">Adresse Fournisseur <span className="text-danger">(*)</span> </label>
                            <input type="text" className="form-control h-100" defaultValue={adresse} onChange={(e) => setAdresse(e.target.value)} required />
                            {<span className="text-danger"></span>}
                        </div>
                        <h5 className="fst-italic my-3 text-secondary"><span className="fw-bold fst-normal ">NB: </span>Veuillez fournir toutes les informations du fournisseur</h5>

                    </div>

                    <div className="row mt-2 p-2 justify-content-center">
                        <div className="col-lg-4 text-center">
                            <button className="btn btn-primary" onClick={() => handleIsdataValide()}> Suivant <i className="bx bx-has-arrow" ></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddFournisseur;