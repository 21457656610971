import { useEffect, useState } from "react";
import TimeLine from "../components/TimeLine";
import { NavLink, useNavigate } from "react-router-dom";
import { token, defaulUrl, deleteLocalStorage, decryptData } from "../components/const";
import Swal from "sweetalert2";

const TerminerValider = () => {
    let storedInfo = JSON.parse(localStorage.getItem('autreInfo'));
    let [autreInfo, setInfo] = useState(storedInfo);
    let [conteneur, setConteneur] = useState(autreInfo?.lib_conteneur);
    let [devise, setDevise] = useState(autreInfo?.devise);
    let [depense, setDepense] = useState(+autreInfo?.depense);
    let [paye, setPaye] = useState(autreInfo?.montant_paye);
    let [prixTotal, setPrixTotal] = useState(0)
    let [montantTotal, setMontantTotal] = useState(0);
    let [valeurjour, setValeurJour] = useState(0);
    let total = 0;
    let [errors, setErrors] = useState(null)

    let MyInfo = {
        'lib_conteneur': conteneur,
        'prix_total': prixTotal,
        'depense': depense,
        'montant_total': montantTotal,
        'montant_paye': paye,
        'devise': devise,
        'valeur_du_jour': valeurjour
    }

    const navigate = useNavigate();
    let status = 0;

    useEffect(() => {
        let p = JSON.parse(localStorage.getItem('selectedProducts'))
        p.forEach(r => {
            total += (+r.prix) * (+r.qte)
        })
        setPrixTotal(total)
        setMontantTotal(+valeurjour + (+depense));
        localStorage.setItem('autreInfo', JSON.stringify(MyInfo));
    }, [MyInfo])
    const handleSaveConteneur = (e) => {
        e.preventDefault();
        let fournisseur = JSON.parse(localStorage.getItem('Fournisseur'))
        let produits = JSON.parse(localStorage.getItem('selectedProducts'))

        if(+paye>+montantTotal){
            Swal.fire({
                title: "Montant trop eleve!",
                text: "Le montant que vous avez saisi dans le champ ' Montant Payé ' dépasse ce que vous devez payer",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }

        fetch(`${defaulUrl}conteneurs/ajout`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ "fournisseur": fournisseur, "produits": produits, "conteneur": MyInfo })
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            console.log(data)
            if (status == 200) {

                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                })
                setErrors({});
                deleteLocalStorage()
                navigate(`/products`)
            } else {
                setErrors(data.errors)
            }
        })
    }
    return (



        <div className="TerminerValider">
            <table>
                <tbody><TimeLine colorFournisseur="success" colorProd="success" colorPrix="success" coloFin="primary" />
                </tbody>
            </table>

            <form >
                <div className="row my-2 p-2 justify-content-center ">
                    <div className="col-md-6 col-lg-4  p-3  shadow rounded">
                        <div className="form-group m-2">
                            <label htmlFor="" className="fs-5">Conteneur (<span className="text-danger fs-5">*</span>)</label>
                            <input type="text" className="form-control" defaultValue={conteneur} onChange={(e) => setConteneur(e.target.value)} required />
                        </div>
                        <div className="form-group m-2">
                            <label htmlFor="" className="fs-5">Devise (<span className="text-danger fs-5">*</span>)</label>
                            <select name="" id="" className="form-select" defaultValue={devise} onChange={(e) => setDevise(e.target.value)} required>
                                <option value="">Votre choix</option>
                                <option value="CFA">CFA</option>
                                <option value="EURO">Euro</option>
                                <option value="GNF">GNF</option>
                                <option value="Dollars">$</option>
                            </select>
                        </div>
                        <div className="form-group m-2">
                            <label htmlFor="" className="fs-5">Prix total(en {devise}) </label>
                            <input disabled type="number" className="form-control" value={prixTotal} />
                        </div>
                        <div className="form-group m-2">
                            <label htmlFor="" className="fs-5">Valeur du jour en GNF (<span className="text-danger fs-5">*</span>)</label>
                            <input type="number" className="form-control" defaultValue={valeurjour} onChange={(e) => setValeurJour(+e.target.value)} />
                        </div>
                        <div className="form-group m-2">
                            <label htmlFor="" className="fs-5">Depenses en GNF (<span className="text-danger fs-5">*</span>)</label>
                            <input type="number" className="form-control" defaultValue={depense} onChange={(e) => setDepense(+e.target.value)} />
                        </div>
                        <div className="form-group m-2">
                            <label htmlFor="" className="fs-5">Motant Total </label>
                            <input disabled type="number" className="form-control" value={montantTotal} />
                        </div>
                        <div className="form-group m-2">
                            <label htmlFor="" className="fs-5">Motant Paye </label>
                            <input type="number" className="form-control" value={paye} onChange={(e) => setPaye(+e.target.value)} required />
                        </div>
                    </div>
                </div>


                <div className="row mt-2 p-2 justify-content-center">
                    <div className="col-lg-4 text-center">
                        <NavLink to="/conteneurs/add-prix" className="btn btn-primary m-1">Precedant</NavLink>
                        <button className="btn btn-success m-1" onClick={(e) => handleSaveConteneur(e)}>Enregistrer</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default TerminerValider;