import { Loading, decryptData, defaulUrl } from "../components/const";
import UseFetch from "../components/UseFetch";
import { NavLink } from "react-router-dom";
import SearchField from "../components/SeachField";
import { useState } from "react";
import Swal from "sweetalert2";
const Contenaires = () => {
    let { data: conteneurs, isloading } = UseFetch('conteneurs/liste');
    let [isLoading, setIsLoading] = useState(false);
    let [detailproduct, setDetailProduct] = useState([]);
    let [date1, setDate1] = useState(null);
    let [date2, setDate2] = useState(null);
    const [trieConteneurs, setTrie] = useState(null);


    const handleDetail = (e, id) => {
        setIsLoading(true)
        e.preventDefault()
        fetch(`${defaulUrl}stocks/liste/${+id}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setDetailProduct(data)
        })

    }

    const handleTriByDate = (e) => {

        if (!date1 || !date2) {

            Swal.fire({
                title: "Date(s) invalides!",
                text: "Choisissez de bonnes dates pour date1 et date 2 ",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });

            return;
        }
        e.preventDefault();
        setTrie(conteneurs.filter((conteneur) => conteneur.created_at >= date1 && conteneur.created_at <= date2 + 1));

    }
    return (
        <div className="Conteneur container-fluid ">
            <div className="row my-2">
                <div className="col-lg-8 h2 text-primary">
                    GESTION DES CONTENEURS
                </div>
                <div className="col-lg-4 text-end">
                    <NavLink to='/conteneurs/add' type="button" className="btn btn-primary ">Nouveau conteneur</NavLink>
                </div>
            </div>

            <div className="row justify-content-between m-0 align-items-center">

                <div className="col-lg-6">
                    <div className="row">
                        <div className="form-group  col-5">

                            <input type="date" className="form-control" id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                        </div>
                        <div className="form-group col-5">
                            <input type="date" className="form-control" id="date2" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                        </div>
                        <button onClick={(e) => handleTriByDate(e)} type="submit" className="btn btn-primary col-2 align-self-end"><i className="bx bx-search" ></i></button>
                    </div>

                </div>

                <div className="col-lg-4">
                    <SearchField plage='plage' conteneur='tr' critere='conteneur' />
                </div>
            </div>

            {
                isloading ? <div className="row p-3 mt-3 justify-content-center"><Loading /></div> : conteneurs ? <div className="table-responsive ">
                    <table className="table table-white shadow table-bordered table-hover table-striped">
                        <thead className="table-primary">
                            <tr >
                                <th >#ID</th>
                                <th>CONTENEURS</th>
                                <th>FOURNISEUR</th>
                                <th>TELEPHONE</th>
                                <th> STATUT</th>
                                <th>DATE AJOUT</th>
                                <th className="text-end">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody className="plage">
                            {
                                trieConteneurs ? trieConteneurs.map((cont, ind) => (
                                    <tr key={cont.id} className="tr">
                                        <td>{ind + 1}</td>
                                        <td className="conteneur">{cont.conteneur}</td>
                                        <td>{cont.Fournisseur}</td>
                                        <td>{cont.telephone}</td>
                                        <td >{cont.statut ? 'Overt' : 'Disponible'}</td>
                                        <td >
                                            {new Date(cont.created_at).toLocaleDateString()}
                                        </td>
                                        <td className="text-end">
                                            <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e) => handleDetail(e, cont.id)}>Voir produits</button>

                                            {(cont.statut === 0) && <button className="btn btn-danger m-1">Supp.</button>}
                                        </td>
                                    </tr>
                                )) :
                                    conteneurs.map((cont, ind) => (
                                        <tr key={cont.id} className="tr">
                                            <td>{ind + 1}</td>
                                            <td className="conteneur">{cont.conteneur}</td>
                                            <td>{cont.Fournisseur}</td>
                                            <td>{cont.telephone}</td>
                                            <td >{cont.statut ? 'Overt' : 'Disponible'}</td>
                                            <td >
                                                {new Date(cont.created_at).toLocaleDateString()}
                                            </td>
                                            <td className="text-end">
                                                <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e) => handleDetail(e, cont.id)}>Voir produits</button>
                                                {(cont.statut === 0) && <button className="btn btn-danger m-1">Supp.</button>}
                                            </td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div> : <div className="row fs-5 py-2 justify-content-center bg-danger-subtle">
                    Aucun conteneur enregistré!
                </div>
            }


            {/* Modal */}
            <div className="AddModal">
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Liste des produit du conteneur</h1>
                                <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">

                                <div className="row p-2 table-responsive">
                                    {isLoading ? <Loading /> : <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>N°</th>
                                                <th>Produit</th>
                                                <th>quantite</th>
                                                <th>Prix Unitaire</th>
                                                <th>Montant</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                detailproduct.map((prod, index) => (
                                                    <tr key={index + 1}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>{prod.produit}</td>
                                                        <td>{prod.quantite}</td>
                                                        <td>{`${prod.prix.toLocaleString()} ${prod.devise}`}</td>
                                                        <td>{`${prod.montant.toLocaleString()} ${prod.devise}`}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>

                                    </table>
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Fin modal */}
        </div >
    );
}

export default Contenaires;