
import CryptoJS from "crypto-js";
import { Navigate, redirect } from "react-router-dom";
export const handleShowPwd = (e) => {
    e.preventDefault();
    let pwdInput = document.querySelector('#show_hide_password input');
    let pwdIcon = document.querySelector('#show_hide_password i');
    if (pwdInput.getAttribute('type') === 'text') {
        pwdInput.setAttribute('type', 'password');
        pwdIcon.classList.add('bx-hide');
        pwdIcon.classList.remove('bx-show');
    } else {
        pwdInput.setAttribute('type', 'text');
        pwdIcon.classList.remove('bx-hide');
        pwdIcon.classList.add('bx-show');
    }

}

export const defaulUrl = 'https://www.fasantextil.com/backend_magasin/public/api/';
// export const defaulUrl = 'http://127.0.0.1:8000/api/';
export const Loading = () => {
    return (
        <div className="Loading">
            <div className="col-12 ">
                <div className="d-flex justify-content-center ">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden me-2">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const token = localStorage.getItem('ACCESS_TOKEN');

export const saveFournisseur = (nom, prenoms, telephone, adresse) => {
    localStorage.removeItem('Fournisseur');
    localStorage.setItem('Fournisseur', JSON.stringify({
        'prenoms': prenoms,
        'nom': nom,
        'telephone': +telephone,
        'adresse': adresse
    }))
}
export const getFournisseur = JSON.parse(localStorage.getItem('Fournisseur'));

export const deleteLocalStorage = () => {
    localStorage.removeItem("Fournisseur");
    localStorage.removeItem("autreInfo");
    localStorage.removeItem("selectedProducts");
}

const myencrypteKey = 'encrypion';
export const encryptData = (name, data) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), myencrypteKey).toString();
    localStorage.setItem(name, encrypted);
}
export const decryptData = (name) => {
    const encrypted = localStorage.getItem(name);
    if (encrypted) {
        const decrypted = CryptoJS.AES.decrypt(encrypted, myencrypteKey).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    }
    return null
}