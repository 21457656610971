import { NavLink } from "react-router-dom";
import UseFetch from "../components/UseFetch";
import { Loading } from "../components/const";
import SearchField from "../components/SeachField";

const Products = () => {
    const { data: conteneurs, isloading } = UseFetch('stocks/liste')
    return (
        <div className="Producs container-fluid">

            <div className="row my-2">
                <div className="col-lg-8 h2 text-primary">
                    LISTE DES ACHATS
                </div>
                <div className="col-lg-4 text-end">
                    <NavLink to='/conteneurs/add' type="button" className="btn btn-primary ">Nouveau conteneur</NavLink>
                </div>
            </div>
            <div className="row ">
                <div className="row justify-content-end m-0">
                    <div className="col-lg-4 py-2">
                        <SearchField plage="plage" conteneur="conteneur" critere="date" />
                    </div>
                </div>
                {
                    isloading ? <Loading /> : conteneurs ? <div className="table-responsive">
                        <table className="table table-white shadow table-bordered table-hover table-striped">
                            <thead className="table-primary ">
                                <tr >
                                    <th >N°</th>
                                    <th>Date</th>
                                    <th>Produit</th>
                                    <th>Quantite</th>
                                    <th> PAU</th>
                                    <th>Montant</th>
                                    <th>N°FACTURE</th>
                                    <th>Conteneur</th>
                                    <th>Fournisseur</th>

                                    {/* <th className="text-end">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody className="plage">
                                {conteneurs.map((cont, ind) => (
                                    <tr key={ind + 1} className="conteneur">
                                        <td>{ind + 1}</td>
                                        <td className="date">{`${new Date(cont.created_at).toLocaleDateString()}`}</td>
                                        <td>{cont.produit}</td>
                                        <td>{cont.quantite}</td>
                                        <td >{cont.prix}</td>
                                        <td >{cont.montant}</td>
                                        <td >{cont.numfact}</td>
                                        <td >{cont.conteneur}</td>
                                        <td >{cont.Fournisseur}</td>



                                        {/* <td className="text-end">
                                                <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#exampleModal">Edite</button>
                                                <button className="btn btn-danger m-1">Supp.</button>
                                            </td> */}
                                    </tr>
                                ))
                                }

                            </tbody>
                        </table>
                    </div> : <div className="row fs-5 py-2 justify-content-center bg-danger-subtle">
                        Aucun conteneur enregistré!
                    </div>
                }

            </div>

        </div>
    );
}

export default Products;