import { useState } from "react";
import { defaulUrl } from "../components/const";
import Swal from "sweetalert2";
const AddPays = () => {
    let status = 0;
    const [errors, setErrors] = useState({});
    const [token, setToken] = useState(localStorage.getItem('ACCESS_TOKEN'));
    const [villes, setVilles] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    const hadleSubmite = (e) => {
        setIsLoading(true)
        e.preventDefault();
        const formData = new FormData(e.target);
        fetch(`${defaulUrl}pays/add`, {
            method: "POST",
            headers: {
                Authorization: `'Bearer ' ${token}`,
                Accept: 'application/json'
            },
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            if (status == 200) {
                setIsLoading(false);
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                })
            } else {
                setErrors(data.errors)
            }
        })
    }


    const hadleAddVille = (e) => {
        e.preventDefault();
        let tbody = document.querySelector('tbody');
        let tr = `<tr class="p-5">
        <td contentEditable class="form-control p-3 w-100"></td>
        
        </tr>`

        tbody.innerHTML += tr;
    }
    return (
        <div className="AddPays">
            <row className="p-2 m-2">
                <h1 className="text-primary text-end">AJOUTER UN PAYS AVEC SES VILLES</h1>
            </row>
            <form className="shadow p-3" >

                <div className="row my-2 p-3 justify-content-center">
                    <div className="form-group col-lg-6">
                        <label htmlFor="pays" className="fs-5">Pays</label>
                        <input type="text" className="form-control h-75" name="pays" id="pays" />
                        {errors.pays && <span className="m-1 text-danger p-2"> {errors.pays}</span>}
                    </div>
                </div>
                <div className="row p-2 m-1 justify-content-center">
                    <div className="col-lg-6 ">
                        <div className="row">Les villes</div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-white">
                                <thead className="table-info">
                                    <tr>
                                        <th className="w-100">Villes</th>
                                        {/* <th>Supp</th> */}
                                    </tr>
                                </thead>

                                <tbody className="p-2 table-white">
                                    <tr className="p-5">

                                        <td contentEditable className="form-control p-3 w-100 villes"></td>
                                        {/* <td className="text-end">
                                            <a className="btn btn-danger text-end remove" onClick={handleRemoveVille(this)}>-</a>
                                        </td> */}
                                    </tr>
                                </tbody>
                                <tr>
                                    <td colSpan="3" className="text-end p-2">
                                        <a className="btn btn-primary" onClick={hadleAddVille}>+</a>
                                    </td></tr>
                            </table>
                        </div>
                    </div>
                </div>


                <div className="row justify-content-end">
                    <div className="col-lg-4 p-2 text-end">
                        <button type="submit" className="btn btn-primary h-100">Enegistrer</button>
                    </div>
                </div>
            </form>
        </div>

    );
}

export default AddPays;