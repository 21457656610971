import { NavLink, useNavigate } from "react-router-dom";
import { defaulUrl, Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
import Select from "react-select";
import Swal from "sweetalert2";
import { useRef, useState } from "react";
const AddUser = () => {
    const { data: magasins, isloading } = UseFetch('magasins/liste');
    let [magasin, setMagasin] = useState(null);
    const navigate = useNavigate();
    let [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [droit, setDroit] = useState(null)
    const useref = useRef();
    const handRegister = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData(e.target);
        let status = 0;
        fetch(`${defaulUrl}register`, {
            method: "POST",
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then((data) => {
            setIsLoading(false);
            console.log(data);
            if (status === 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {
                    useref.current.click();
                    navigate('/users');
                })
            } else {
                setErrors(data.errors);
            }
        })
    }
    return (
        <div className="modal fade" id="modalUser"  >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Ajouter un utilisateur</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={handRegister}>
                        <div className="row modal-body">
                            <div className="form-group mb-2 ">
                                <label htmlFor="email" className="fs-5">Email</label>
                                <input type="email" className="form-control h-75" name="email" id="email" placeholder="Adresse email de l'utilisateur" />
                                {errors.email && <span className="text-danger my-2">{errors.email}</span>}
                            </div>
                            <div className="form-group my-2 p-3">
                                <label htmlFor="droit" className="fs-5">Droit d'accès</label>
                                <select name="droit" id="droit" className="form-select h-75" onChange={(e) => setDroit(e.target.value)}>
                                    <option value="">Choisir un droit</option>
                                    <option value="Administrateur">Administrateur</option>
                                    <option value="Magasin">Vendeur</option>
                                    <option value="Comptable">Comptable</option>
                                </select>
                                {errors.droit && <span className="text-danger my-2">{errors.droit}</span>}
                            </div>
                            <div  className="form-group my-1">
                                <label htmlFor="droit" className="fs-5">Magasins</label>
                                {isloading ? <Select /> :
                                    <Select defaultInputValue={magasin?.value} options={
                                        magasins.map((m) => (
                                            {
                                                value: m.id, label: m.mag
                                            }
                                        ))}
                                        onChange={setMagasin}
                                        name="magasin"
                                    />
                                }
                            </div>

                        </div>
                        <div className="modal-footer">

                            <a className="btn btn-danger" data-bs-dismiss="modal" ref={useref}> Annuler</a>
                            <button className="btn btn-primary d-flex justify-content-center align-items-center">{isLoading && <Loading />} Enregistrer</button>

                        </div>
                    </form>

                </div>
            </div>
        </div>

    );
}
export default AddUser;