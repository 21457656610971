import { useState, useEffect } from "react";
import { decryptData, defaulUrl, Loading, token } from "../components/const";
import Swal from "sweetalert2";
import UseFetch from "../components/UseFetch";
import SearchField from "../components/SeachField";


const Pays = () => {
    // const [pays, setPays] = useState(null);
    let [loading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState({});
    let status = 0;
    const { data: pays, isloading } = UseFetch('pays/liste');

    const handleSubmite = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        fetch(`${defaulUrl}pays/add`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json'
            },
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            if (status == 200) {
                setIsLoading(false);
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                })

                setErrors({});
            } else {
                setErrors(data.errors)
            }
        })
    }

    const handleDelete = (e, id) => {
        e.preventDefault();

        Swal.fire({
            title: "Suppression",
            text: "Vous allez supprimer ce pays?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            confirmButtonColor: "red",
            cancelButtonColor: "blue"
        }).then((resp) => {
            if (resp.isConfirmed) {
                setIsLoading(true)
                fetch(`${defaulUrl}pays/delete/${id}`, {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + decryptData('ACCESS_TOKEN'),
                        Accept: 'application/json'
                    }
                }).then((response) => {
                    status = response.status;
                    return response.json();
                }).then((data) => {

                    if (status == 200) {
                        Swal.fire({
                            title: "Suppression de pays",
                            text: data.message,
                            icon: "success"
                        }).then(() => {
                            setIsLoading(false)
                        });


                    }
                })
            }
        })
    }

    return (
        <div className="Pays">
            <div className="row p-2 my-2">
                <div className="row "><h3 className="text-primary text-end">GESTION DES PAYS</h3></div>
            </div>
            <form className="shadow p-3" onSubmit={(e) => handleSubmite(e)} >
                <div className="row my-2 p-2 justify-content-center">
                    <div className="form-group col-lg-6">
                        <label htmlFor="pays" className="fs-5">Nouveau Pays</label>
                        <input type="text" className="form-control h-50" name="pays" id="pays" />
                        {errors.pays && <span className="m-1 text-danger p-2"> {errors.pays}</span>}
                        <div className="row justify-content-end">
                            <div className="col-lg-4 p-2 text-end">
                                <button type="submit" className="btn btn-primary h-100">Enegistrer</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>


            <div className="row p-2 my-2">
                <div className="row justify-content-end">
                    <div className="col-lg-4">
                        <SearchField plage="pays" conteneur="tr" critere="p" />
                    </div>
                    <div className="table-responsive mt-2">
                        <table className="table table-white shadow table-bordered table-hover table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th hidden>#ID</th>
                                    <th>#ID</th>
                                    <th>Pays</th>
                                    <th>Date ajout</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="pays">
                                {isloading && <tr><td colSpan="5" className="p-3"> <Loading /></td></tr>}

                                {pays ? pays.map((pa, index) => (
                                    <tr key={pa.id} className="tr" >
                                        <td hidden> {pa.id}</td>
                                        <td> {index + 1}</td>
                                        <td className="p"> {pa.pays}</td>
                                        <td> {new Date(pa.created_at).toLocaleDateString()}</td>
                                        <td className="text-end">
                                            <button className="btn btn-primary m-1" disabled>Editer</button>
                                            <button className="btn btn-info m-1" >Voir villes</button>
                                            <button className="btn btn-danger m-1" onClick={(e) => handleDelete(e, pa.id)}>Supp.</button>
                                        </td>
                                    </tr>
                                )) : <tr><td rowSpan="4"> Aucun pays enregistré!</td></tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Pays;