import { decryptData } from "../components/const";
import DbMagasin from "../Magasins/DbMagasin";
import DbAdmin from "./dbAdmin";

const Dashboard = () => {
    const user = decryptData('USER');
    return (
        <>
            {user.droit === 'Administrateur' && <DbAdmin />}
            {user.droit === 'Magasin' && <DbMagasin />}
        </>

    );
}

export default Dashboard;