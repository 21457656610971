import { useState } from "react";
import { defaulUrl, Loading, decryptData } from "../components/const";
import Swal from "sweetalert2";
import UseFetch from "../components/UseFetch";
import SearchField from "../components/SeachField";
const Ville = () => {

    let [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    let status = 0;
    const { data: pays, isloading } = UseFetch('pays/liste')
    const { data: ville } = UseFetch('villes/liste')

    const handleDelete = (e) => {

    }
    const handleSubmite = (e) => {
        setIsLoading(true)
        e.preventDefault();
        const formData = new FormData(e.target);
        fetch(`${defaulUrl}villes/add`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json'
            },
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                })

                setErrors({});
            } else {
                setErrors(data.errors)
            }
        })
    }
    return (
        <div className="Ville">
            <div className="row p-2 my-2">
                <div className="row "><h1 className="text-primary text-end">GESTION DES VILLES</h1></div>
            </div>
            <form className="shadow p-3" onSubmit={(e) => handleSubmite(e)} >
                <div className="row my-2 p-2 justify-content-center ">
                    <div className="col-lg-8">
                        <div className="row p-3">
                            
                            <div className="form-group col-lg-6 my-5">
                                <label htmlFor="pays" className="fs-5">Pays</label>
                                <select name="pays" id="pays" className="form-select h-100">
                                    <option value=""> {isloading ? "Chargement..." : "Faites un choix"} </option>
                                    {pays ?
                                        pays.map((pay) => (
                                            <option key={pay.id} value={pay.id}>{pay.pays}</option>
                                        )) : <option value="">Aucun pays enregistré</option>
                                    }
                                </select>
                                {errors.pays && <span className="m-1 text-danger p-2"> {errors.pays}</span>}
                            </div>
                            <div className="form-group col-lg-6 my-5">
                                <label htmlFor="ville" className="fs-5">Ville</label>
                                <input type="text" className="form-control h-100" name="ville" id="pays" />
                                {errors.ville && <span className="m-1 text-danger p-2"> {errors.ville}</span>}
                            </div>
                        </div>
                        <div className="row justify-content-end mt-3">
                            <div className="col-lg-4 p-2 text-end">
                                <button type="submit" className="btn btn-primary h-100 d-flex justify-content-center align-items-center">{isLoading && <Loading />} Enegistrer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>


            <div className="row p-2 my-2">
                <div className="row justify-content-end">
                    <div className="col-lg-4">
                     <SearchField plage="plage" conteneur="tr" critere="pays"/>
                    </div>
                    <div className="table-responsive mt-2">
                        <table className="table table-white shadow table-bordered table-hover table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th hidden>#ID</th>
                                    <th>#ID</th>
                                    <th>Ville</th>
                                    <th>Pays</th>
                                    <th>Date ajout</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="plage">
                                {isloading ? <tr><td colSpan="5" className="p-3"> <Loading /></td></tr> :


                                    ville ? ville.map((pa, index) => (
                                        <tr key={pa.id} className="tr">
                                            <td hidden> {pa.id}</td>
                                            <td> {index + 1}</td>
                                            <td> {pa.ville}</td>
                                            <td className="pays"> {pa.pays}</td>
                                            <td> {new Date(pa.created_at).toLocaleDateString()}</td>
                                            <td className="text-end">
                                                <button className="btn btn-primary m-1" disabled>Editer</button>
                                                <button className="btn btn-danger m-1" onClick={(e) => handleDelete(e, pa.id)}>Supp.</button>
                                            </td>
                                        </tr>
                                    )) : <tr><td rowSpan="4"> Aucun pays enregistré!</td></tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Ville;