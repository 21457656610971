import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Loading } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import TimeLine from "../components/TimeLine";
import Swal from "sweetalert2";




const ListeProduit = ({ titre }) => {


    const { data: produits, isloading } = UseFetch('produits/liste')
    let [colorMode, setColorMode] = useState(['text-primary', 'text-dark'])
    let selectedProds = localStorage.getItem('selectedProducts') ? JSON.parse(localStorage.getItem('selectedProducts')) : null;
    let [modeListe, setMode] = useState(false)
    let [prods, setProds] = useState(selectedProds ? selectedProds : []);

    const navigate = useNavigate();

    const isSelected = (id) => {
        return prods.find(p => p.id === id && p.qte > 0) ?? false
    }
    const handleSelectProduct = (e, prod) => {
        if (!(prods.find(p => p.id === prod.id))) {
            setProds([
                ...prods,
                {
                    'id': prod.id,
                    'qte': 1,
                    'prix': 0,
                    'prod': prod.produit,
                }
            ]);
        }

    }

    const handleUnSelectProduct = (e, prod) => {
        e.preventDefault()
        if (prods.find(p => p.id === prod.id)) {
            setProds(prods.filter(elm => elm.id !== prod.id))
        }
    }
    const handleSuivant = (e) => {
        if (prods.length == 0) {
            Swal.fire({
                title: "Aucun produit selectionné!",
                text: "Veillez selectionner au moins un produit",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
        } else {
            navigate('/conteneurs/add-prix');
        }
    }

    useEffect(() => {
        localStorage.setItem('selectedProducts', JSON.stringify(prods))
    }, [prods])
    return (


        <div className="Prouits">
            <table>
                <tbody>
                    <TimeLine colorFournisseur="success" colorProd="primary" colorPrix="secondary-subtle" coloFin="secondary-subtle" />
                </tbody>
            </table>

            {
                isloading ? <Loading /> : <div className="ListeProduits">
                    <div className="row justify-content-end">
                        <div className="col-lg-4  text-end">
                            <i className={`bx bx-grid-alt fs-4  btn p-0 ${colorMode[0]}`}
                                onClick={() => { setMode(false); setColorMode(['text-primary', 'text-dark']) }}></i>
                            <i className={`bx bx-list-ul fs-3   btn p-0 ${colorMode[1]}`}
                                onClick={() => { setMode(true); setColorMode(['text-dark', 'text-primary']) }}></i>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            {
                                // selectProduct.length > 0 && <h4 className="fst-italic">{selectProduct.length} Produits(s) selectionné(s)</h4>
                            }
                        </div>
                        <div className="col-lg-4">  <SearchField /></div>
                    </div>
                    {
                        produits ? modeListe ?
                            <div className="table-responsive mt-1 p-2">
                                <table className="table table-hover table-striped table-secondary">
                                    <thead className="table-dark">
                                        <tr>
                                            <td hidden>ID</td>
                                            <td >N°</td>
                                            <td >Statut</td>
                                            <td>Designation Produit</td>
                                            <td className="text-end">Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            produits.map((prod, i) => (
                                                <tr key={prod.id}>
                                                    <td hidden>{prod.id}</td>
                                                    <td >{i + 1}</td>
                                                    <td > {isSelected(prod.id) ? <i className="bx bx-message-square-check fs-4 text-success" ></i> : <i className="bx bx-message-square fs-4 text-secondary" ></i>} </td>
                                                    <td >{prod.produit}</td>
                                                    <td className="text-end">
                                                        {!isSelected(prod.id) && <button className="btn btn-primary m-1" onClick={(e) => handleSelectProduct(e, prod)} >Choisir</button>}
                                                        {isSelected(prod.id) && <button className="btn btn-danger m-1" onClick={(e) => handleUnSelectProduct(e, prod)} >Retirer</button>}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className="row mt-2 p-2">
                                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                                    {
                                        produits.map((prod) => (
                                            <div className="col p-2" key={prod.id}>
                                                <div className="card h-100  rounded-5 p-3 card-magasin pointer" >
                                                    <h5 className="text-center">{prod.produit}</h5>
                                                    <div className="row justify-content-between align-items-center">
                                                        <div className="col-lg-4">
                                                            {isSelected(prod.id) && <div className="row align-items-center">
                                                                <i className="bx bx-message-square-check fs-4 text-success" ></i>

                                                            </div>}
                                                        </div>
                                                        <div className="col-lg-6 text-center">
                                                            {!isSelected(prod.id) && <button className="btn btn-primary m-1" onClick={(e) => handleSelectProduct(e, prod)} >Choisir</button>}
                                                            {isSelected(prod.id) && <button className="btn btn-danger m-1" onClick={(e) => handleUnSelectProduct(e, prod)} >Retirer</button>}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            : <div className="row p-3 m-2 bg-danger-subtle">
                                <h5 className="text-center">Aucun produit n'a ete enregister</h5>
                            </div>
                    }
                </div>
            }

            <div className="row mt-2 p-2 justify-content-center">
                <div className="col-lg-4 text-center">
                    {/* <BtnChange text="< Precedant" page="Fournisseur" />
                    <BtnChange text="Suivant >" page="Prix" /> */}

                    <NavLink to="/conteneurs/add" className="btn btn-primary m-1">Precedant</NavLink>
                    <button className="btn btn-primary m-1" onClick={() => handleSuivant()}>Suivant</button>
                </div>


            </div>
        </div>
    );

}

export default ListeProduit;