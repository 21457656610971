import { useEffect, useState } from "react";
import TimeLine from "../components/TimeLine";
import { Outlet } from "react-router-dom";
import { BrowserRouter as Routes, Router, Route } from "react-router-dom";
import AddFournisseur from "../Fournisseurs/AddFournisseur";

const Addconteneur = () => {
    return (
        <div className="add-conteneur">
            <AddFournisseur/>
        </div>
    );
}

export default Addconteneur;