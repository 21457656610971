import { NavLink } from "react-router-dom";
import { handleMenu } from "./menu";
const MenuMagasin = () => {
    return (
        <ul className="metismenu " id="menu" >
            <li>
                <NavLink to='/'>
                    <div className="parent-icon"><i className='bx fs-4 bx-home-circle'></i>
                    </div>
                    <div className="menu-title">Tableau de bord</div>
                </NavLink>
            </li>
            <li className="dropdown">
                <a  className=" bg-secondary-subtle d-flex align-items-center h-100 p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Gestion vente
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/facturation'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Facturation
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/conteneurs'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Ventes du jour
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='approvisions' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Liste vente
                        </NavLink>
                    </li>
                    <NavLink to='/products/stock-actuel'>
                        <i className="bx fs-4 bx-right-arrow-alt">
                        </i>vente/conteneur
                    </NavLink>

                </ul>
            </li>
            <li className="dropdown">
                <a  className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow"   onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Gestion stock
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/magasins/stocks'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Stock actuel
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products/stock-du-mois' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Stock mois
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/approvisions'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Mes Approvisions
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdown">
                <a  className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow"  onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Clients
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/fournisseurs' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Mes Clients
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/fournisseurs/solde'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Solde Client
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to='/fournisseurs/factures'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Factures non payees
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdown">
                <a  className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow"  onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Comptabilité
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/products/categories'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Caisse
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products/add' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Suppression vente
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Recouvrement client
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Rapport
                        </NavLink>
                    </li>
                </ul>
            </li>

        </ul>
    );
}

export default MenuMagasin;