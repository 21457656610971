import { createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./Administration/DefaultLayout";
import Products from "./Produits/Products";
import Categories from "./Produits/Categories";
import AddProduct from "./Produits/AddProduct";
import EditeProduct from "./Produits/EditeProduct";
import Dashboard from "./Administration/Dashboard";
import AddMagasin from "./Magasins/AddMagasin";
import Magasins from "./Magasins/Magasins";
import AddContenaires from './Contenaires/AddContenaire'
import Contenaires from './Contenaires/Contenaires'
import AddApprovision from "./Approvision/AddApprovision";
import Approvisions from "./Approvision/Approvisions";
import Users from "./Authentification/Users";
import AddUser from "./Authentification/AddUser";
import Profile from "./Authentification/Profile";
import Login from "./Authentification/Login"
import GuestLayout from "./Administration/GuestLayout";
import GetPwd from './Authentification/GetPwd'
import Pays from "./Settings/Pays";
import Ville from "./Settings/Ville";
import Commune from "./Settings/Commune";
import Quartier from "./Settings/Quartier";
import AddPays from "./Settings/AddPays";
import ListeProduit from "./Produits/ListeProduits";
import SelectedProducts from "./Produits/SelectedProducts";
import TerminerValider from "./Contenaires/TerminerValider";
import Stocks from "./Produits/Stocks";
import StokMois from "./Produits/StockMois";
import Fournisseurs from "./Fournisseurs/Fournisseurs";
import Solde from "./Fournisseurs/Solde";
import FacturesImpayes from "./Fournisseurs/FacturesImpayes";
import DashBoardMag from "./Magasins/DashBoardMag";
import ListeProduitParMagasin from "./Magasins/ListeProduitParMagasin";
import StockMagasin from "./Produits/StocksMagasins";
import Facturation from "./Ventes/Facturation";
import Addconteneur from "./Contenaires/AddContenaire";
import Addconteneurs from "./Contenaires/AddConteneur";
const router = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                path: '/',
                element: <Dashboard />
            },
            {
                path: '/home',
                element: <Dashboard />
            },
            // Produits
            {
                path: '/products',
                element: <Products />
            },
            {
                path: '/products/categories',
                element: <Categories />
            },
            {
                path: '/products/add',
                element: <AddProduct />
            },
            {
                path: '/products/:id',
                element: <EditeProduct />
            },
            {
                path: '/products/stock-actuel',
                element: <Stocks />
            },
            {
                path: '/products/stock-du-mois',
                element: <StokMois />
            },

            // Magasin
            {
                path: '/magasins/add',
                element: <AddMagasin />
            },


            {
                path: '/magasins/liste',
                element: <Magasins />
            },
            {
                path: '/magasins',
                element: <DashBoardMag />
            },
            // Contenaires



            {
                path: '/conteneurs/add',
                element: <Addconteneur />
            },
            {
                path: '/conteneurs/add-products',
                element: <ListeProduit />
            },
            {
                path: '/conteneurs/add-prix',
                element: <SelectedProducts />
            },
            {
                path: '/conteneurs/add-enregistrer',
                element: <TerminerValider />
            },
            {
                path: '/conteneurs',
                element: <Contenaires />,

            },

            // Approvisionnement
            {
                path: '/approvisions/add',
                element: <AddApprovision />
            },
            {
                path: '/approvisions',
                element: <Approvisions />
            },
            // Pays, villes, communes et quartiers
            {
                path: '/parametres/pays',
                element: <Pays />
            },
            {
                path: '/parametres/pays/add',
                element: <AddPays />
            },
            {
                path: '/parametres/villes',
                element: <Ville />
            },
            {
                path: '/parametres/communes',
                element: <Commune />
            },
            {
                path: '/parametres/quartiers',
                element: <Quartier />
            },
            // ROUTES EN FONCTION DES MAGASINS 
            {
                path: '/magasins/stocks',
                element: <ListeProduitParMagasin />//lISTE DES PRODUITS PAR MAGASIN(compte magasin)
            },
            {
                path: '/magasins/:id/stocks',
                element: <StockMagasin />//lISTE DES PRODUITS PAR MAGASIN(compte admin)
            },

            // GESTION DES VENTES

            {
                path: '/facturation',
                element: <Facturation />
            },

            // Fournisseurs
            {
                path: '/fournisseurs',
                element: <Fournisseurs />
            },
            {
                path: '/fournisseurs/solde',
                element: <Solde />
            },
            {
                path: '/fournisseurs/factures',
                element: <FacturesImpayes />
            },
            // Users
            {
                path: '/users',
                element: <Users />
            },
            {
                path: '/users/add',
                element: <AddUser />
            },
            {
                path: '/users/profile',
                element: <Profile />
            },



        ]
    },

    {
        path: '/',
        element: <GuestLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/password',
                element: <GetPwd />
            },
        ]
    }


]);

export default router;