import { useParams } from "react-router-dom";
import SearchField from "../components/SeachField";
import { Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
import {  useState } from "react";
const StockMagasin = () => {
    const { id } = useParams();
    const { data: produits, isloading } = UseFetch(`mag/${id}/stocks`);
    const { data: stocks, isloading: load } = UseFetch(`mois/${2}`);
    const [stockBymois, setByMois] = useState(null);

    let mois = stocks?.map(p => p.mois)
    mois = mois?.filter((item, index) => mois.indexOf(item) === index)
    return (
        <div className="produit">
            <div className="row">
                <div className="col-lg-6 h3 text-primary"> SITUATION DU STOCK DU MAGASIN #{id}</div>
            </div>
            <div className="accordion accordion-flush" id="stock">
                <div className="accordion-item my-3 shadow">
                    <h2 className="accordion-header ">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#stock-actuel" aria-expanded="false" aria-controls="stock-actuel">
                            <span className="fs-4"> Stock Actuel</span>
                        </button>
                    </h2>
                    <div id="stock-actuel" className="accordion-collapse collapse" data-bs-parent="#stock">
                        <div className="accordion-body bg-transparent">
                            <div className="row justify-content-end">
                                <div className="col-lg-6">
                                    <SearchField />
                                </div>
                            </div>
                            <div className="row p-2">
                                {
                                    isloading ? <Loading /> : produits.length ? <div className="table-responsive">

                                        <table className="table table-white table-bordered table-hover table-striped">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th>N°</th>
                                                    <th>PRODUITS</th>
                                                    <th>QUANTITE</th>
                                                    <th>PVU</th>
                                                </tr>

                                            </thead>

                                            <tbody>
                                                {
                                                    produits.map((prod, index) => (
                                                        (prod.statut === 'Validé') && <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{prod.produit}</td>
                                                            <td>{prod.quantite}</td>
                                                            <td>{prod.pvu}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>

                                        </table>

                                    </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger">Aucun produit trouve</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item my-3 shadow">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#stock-attente" aria-expanded="false" aria-controls="stock-attente">
                            <span className="fs-4"> Stock du mois</span>
                        </button>
                    </h2>
                    <div id="stock-attente" className="accordion-collapse collapse" data-bs-parent="#stock">
                        <div className="accordion-body">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6">
                                    <select name="" id="" className="form-select" onChange={(e) => setByMois(
                                        stocks.filter(stock => stock.mois === e.target.value)
                                    )}>
                                        <option value="">{load ? 'Chargement...' : 'Choisir un mois'}</option>
                                        {
                                            mois && mois.map((m, i) => (
                                                <option key={i} value={m}>{m}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-lg-6">
                                    <SearchField />
                                </div>
                                {
                                    stockBymois ?
                                        <div className="table-responsive p-2 ">
                                            <table className="table table-white table-hover table-striped">
                                                <thead className="table-primary">
                                                    <th>N°</th>
                                                    <th>PRODUIT</th>
                                                    <th>QTE INITIALE</th>
                                                    <th>QTE APPRO</th>
                                                    <th>QTE QTE TOTALE</th>
                                                    <th>QTE QTE VENDU</th>
                                                    <th>QTE QTE RESTANTE</th>
                                                    <th>QTE QTE APPRO ATTENTE</th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        stockBymois.map((stock,i)=>(
                                                            <tr key={stock.id}>
                                                                <td>{i+1}</td>
                                                                <td>{stock.produit}</td>
                                                                <td>{stock.qte_initiale}</td>
                                                                <td>{stock.qte_appro}</td>
                                                                <td>{stock.qte_initiale+stock.qte_appro}</td>
                                                                <td>{stock.qte_vendu}</td>
                                                                <td>{(stock.qte_initiale+stock.qte_appro)-stock.qte_vendu}</td>
                                                                <td>{stock.qte_appro_attente}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        : <div className="row p-2 bg-danger-subtle justify-content-center">Aucun stock trouve</div>}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="accordion-item my-3 shadow">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <span className="fs-4"> Stock en attente</span>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#stock">
                        <div className="accordion-body">
                            <div className="row justify-content-end">
                                <div className="col-lg-6">
                                    <SearchField />
                                </div>
                            </div>

                            <div className="row p-2">
                                {
                                    isloading ? <Loading /> : produits.length ? <div className="table-responsive">

                                        <table className="table table-white table-bordered table-hover table-striped">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th>N°</th>
                                                    <th>PRODUITS</th>
                                                    <th>QUANTITE</th>
                                                    <th>PVU</th>
                                                </tr>

                                            </thead>

                                            <tbody>
                                                {
                                                    produits.map((prod, index) => (
                                                        (prod.statut === 'En attente') && <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{prod.produit}</td>
                                                            <td>{prod.quantite}</td>
                                                            <td>{prod.pvu}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>

                                        </table>

                                    </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger">Aucun produit trouve</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>








        </div>
    );
}

export default StockMagasin;