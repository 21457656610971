import { NavLink, Outlet, Navigate, useNavigation, useNavigate } from "react-router-dom";
import { decryptData, token } from "../components/const";
import MenuAdnin from "./MenuAdmin";
import MenuMagasin from "./MenuMagasin";



const DefaultLayout = () => {
const navigator=useNavigate()
    const handleShowMenu = (e) => {
        const wrapper = document.querySelector('.wrapper')
        wrapper.classList.toggle('toggled')
    }
    if (!decryptData('ACCESS_TOKEN')) {
        return <Navigate to='/login' />
    }
    const user = decryptData('USER');
    const logout = () => {
        localStorage.clear();
        navigator('/login');
    }
    return (
        <div className="Dashboard wrapper">
            <div className="sidebar-wrapper scrollable" data-simplebar="true">
                <div className="sidebar-header">
                    <div>
                        <img src="images/store.png" className="logo-icon" alt="logo icon" />
                    </div>
                    <div>
                        <h5 className="logo-text p-2 ">FasanTextil</h5>
                    </div>
                    <div className="toggle-icon ms-auto" onClick={handleShowMenu}><i className='bx fs-4  bx-arrow-to-left'></i>
                    </div>
                </div>
                {user.droit === 'Administrateur' && <MenuAdnin />}
                {user.droit === 'Magasin' && <MenuMagasin />}
            </div>



            <header>
                <div className="topbar d-flex align-items-center">
                    <nav className="navbar navbar-expand">
                        <div className="mobile-toggle-menu" onClick={handleShowMenu}><i className='bx fs-4 bx-menu'></i>
                        </div>
                        <div className="setting-user-box ms-auto  d-flex justify-content-end align-items-center">
                            {user.droit === 'Administrateur' && <div className=" dropdown ms-auto">
                                <NavLink className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bx bx-cog fs-3"></i>
                                </NavLink>
                                <ul className="dropdown-menu dropdown-menu-end">
                                   
                                    <li><NavLink to='/parametres/pays' className="dropdown-item" >
                                        <i className=" fs-4 "></i><span>Gestion Pays</span></NavLink>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider mb-0"></div>
                                    </li>
                                    <li><NavLink to='/parametres/villes' className="dropdown-item " >
                                        <i className='fs-4 '></i><span>Gestion Villes</span></NavLink>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider mb-0"></div>
                                    </li>
                                    <li><NavLink to='/users' className="dropdown-item" >
                                        <i className=" fs-4 "></i><span>Gestion Utilisateurs</span></NavLink>
                                    </li>

                                </ul>
                            </div>}
                            <div className="user-box dropdown ms-auto">
                                <NavLink className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={`./images/user.png`} className="user-img" alt="user avatar" />
                                    <div className="user-info ps-3">
                                        <p className="user-name mb-0">{user?.login}</p>
                                        <p className="designattion mb-0"> {user?.droit}</p>
                                    </div>
                                </NavLink>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><NavLink to='/users/profile' className="dropdown-item" ><i className="bx fs-4 bx-user"></i><span>Mon profile</span></NavLink>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider mb-0"></div>
                                    </li>
                                    <li><NavLink to='/users/profile' className="dropdown-item" ><i className="bx fs-4 bx-hide"></i><span>Mot de passe</span></NavLink>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider mb-0"></div>
                                    </li>
                                    <li><button onClick={() => logout()} className="dropdown-item text-danger" ><i className='bx fs-4 bx-log-out-circle'></i>Se déconnecter</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>


            <div className="page-wrapper">
                <div className="page-content ">
                    <Outlet />
                </div>
            </div>


            <div className="overlay toggle-icon"></div>

            <NavLink className="back-to-top"><i className='bx fs-4 bxs-up-arrow-alt'></i></NavLink>

            <footer className="page-footer">
                <p className="mb-0">Copyright © 2024. Tout droit reservé.</p>
            </footer>
        </div>
    );
}


export default DefaultLayout;