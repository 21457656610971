

import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import { Loading } from "../components/const";
import ModalRemboursement from "./ModalRemboursement";

const FacturesImpayes = () => {
    const { data: fournisseurs, isloading } = UseFetch('fournisseurs/factures');

    return (
        <div className="Factures">
            <div className="row my-2">
                <div className="col-lg-6 h2 text-primary">
                    FACTURES NON PAYEES
                </div>
                <div className="col-lg-6 text-end">
                    <SearchField />
                </div>
            </div>
            {
                isloading ? <Loading /> : fournisseurs ?
                    <div className="table-responsive p-2">
                        <table className="table table-hover table-striped ">
                            <thead className="table-primary">
                                <tr>

                                    <td>N°</td>
                                    <td>NUM-FACTURE</td>
                                    <td>PRENOMS</td>
                                    <td>NOM</td>
                                    <td>TELEPHONE</td>
                                    <td>ADRESSE</td>
                                    <td>MONTANT TOTAL</td>
                                    <td>MONTANT PAYE</td>
                                    <td>RESTE A PAYER</td>
                                    <td className="text-end">ACTIONS</td>
                                </tr>

                            </thead>
                            <tbody>
                                {
                                    fournisseurs && fournisseurs.map((fourn, index) => (
                                        <tr key={index}>

                                            <td>{index + 1}</td>
                                            <td>{fourn.numero}</td>
                                            <td>{fourn.prenoms}</td>
                                            <td>{fourn.nom}</td>
                                            <td>{fourn.telephone}</td>
                                            <td>{fourn.adresse}</td>
                                            <td>{fourn.montant}</td>
                                            <td>{fourn.paye}</td>
                                            <td>{fourn.solde}</td>
                                            <td className="text-end" >
                                                <button className="btn btn-primary m-1" disabled>DETAILS </button>
                                                <button className="btn btn-success m-1" data-bs-toggle="modal" data-bs-target="#modalRemboursement" disabled>REMBOURSER</button>
                                            </td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div> :
                    <div className="row justify-content-center p-2 bg-danger-subtle text-danger fs-5">Aucun fournisseur trouvé!</div>
            }

            {/* Modal de paye */}
            <ModalRemboursement />
        </div>
    );
}

export default FacturesImpayes;