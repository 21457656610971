import { NavLink } from "react-router-dom";
import { handleMenu } from "./menu";
const MenuAdnin = () => {
    return (
        <ul className="metismenu " id="menu" >
            <li>
                <NavLink to='/'>
                    <div className="parent-icon"><i className='bx fs-4 bx-home-circle'></i>
                    </div>
                    <div className="menu-title">Tableau de bord</div>
                </NavLink>
            </li>

            <li className="dropdwon">
                <a  className=" bg-secondary-subtle d-flex align-items-center h-100 p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Achats-Appro
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/products'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Gestion Achats
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='approvisions' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Gestion Appro.
                        </NavLink>
                    </li>
                    <NavLink to='/products/stock-actuel'>
                        <i className="bx fs-4 bx-right-arrow-alt">
                        </i>Stock Actuel
                    </NavLink>

                </ul>
            </li>
            <li className="dropdwon" hidden>
                <a  className=" bg-secondary-subtle h-100 d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Gestion Ventes
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/products/categories'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i> Ventes du jour
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products/categories'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Les Ventes
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products/add' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Vents/Conteneurs
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdwon">
                <a  className=" bg-secondary-subtle d-flex align-items-center h-100 p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Produits & Catego...
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/products/categories'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Gestion Categories
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products/add' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Gestion Produits.
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdwon">
                <a  className=" bg-secondary-subtle d-flex align-items-center h-100 p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Magasins-Conten...
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/magasins/liste'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Gestion Magasins
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/conteneurs' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Gestion Conteneurs
                        </NavLink>
                    </li>
                </ul>
            </li>

            <li className="dropdwon">
                <a  className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Fournisseurs
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/fournisseurs' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Mes Fornisseurs
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/fournisseurs/solde'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Solde Fournisseur
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to='/fournisseurs/factures'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Factures non payees
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdwon" hidden>
                <a  className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Clients
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/fournisseurs' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Mes Clients
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/fournisseurs/solde'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Solde Client
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to='/fournisseurs/factures'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Factures non payees
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdwon" hidden>
                <a  className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <i className="bx fs-4 bx-category ms-2"></i>
                    Comptabilité
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/products/categories'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Rapport Comptable
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products/categories'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Rapport Magasin
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Solde comptable
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products/add' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Soldes magasins
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to='/products'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Bénefices
                        </NavLink>
                    </li>

                </ul>
            </li>

        </ul>
    );
}
export default MenuAdnin;